import Vue from 'vue';
import App from './App';
import router from './router';
import VueCompositionAPI from '@vue/composition-api';
import ElementUI from 'element-ui';
import tool from './mixins/tool';
import 'element-ui/lib/theme-chalk/index.css';
//引入vant
import Vant from 'vant';
import 'vant/lib/index.css';
import { Toast } from 'vant';

//复制功能
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

// 下拉刷新
import infiniteScroll from 'vue-infinite-scroll' 
Vue.use(infiniteScroll)

// const app = createApp();
Vue.use(Toast);
Vue.use(Vant);

Vue.use(ElementUI);
Vue.use(VueCompositionAPI);

Vue.mixin(tool);

new Vue({
  el: '#app',
  router: router,
  render: h => h(App)
});
