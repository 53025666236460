const Tool = {
  data() {
    return {
      token: null
    };
  },
  methods: {
    mixin_redirectFromUrl(withoutRedirectUrl, crossDomain) {
      const token = localStorage.getItem('jwtToken');
      let url = '';
      if (token && token !== 'null' && token !== 'undefined') {
        this.token = token;
        const localTo = this.$route.query.localTo || this.mixin_getQueryString('localTo');
        if (localTo) {
          this.$router.replace(localTo);
          return;
        }
        let redirectUrl = this.$route.query.redirectUrl || this.mixin_getQueryString('redirectUrl');
        if (!redirectUrl && crossDomain && withoutRedirectUrl) {
          redirectUrl = withoutRedirectUrl;
        }
        if (redirectUrl) {
          const query = this.mixin_ObjectToQuery({ token: token });
          if (/\#/.test(redirectUrl)) {
            url = `${redirectUrl}?${query}`;
          } else {
            url = `${redirectUrl}/#/?${query}`;
          }
          console.log(url);
        } else if (withoutRedirectUrl) {
          url = withoutRedirectUrl;
        }
      }
      if (url) location.href = url;
    },
    mixin_getAllQueryString() {
      const url = location.search;
      const theRequest = new Object();
      if (url.indexOf("?") != -1) {
        const str = url.substr(1);
        const strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
      }
      return theRequest;
    },
    mixin_getQueryString(name) {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      const r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    mixin_ObjectToQuery(param) {
      return Object.keys(param).map(key => `${key}=${param[key]}`).join('&');
    }
  }
};

export default Tool;
