<template>
  <div class="warp">
    <div class="" v-show="$route.meta.navShow">
      <ul>
       
      </ul>
    </div>
    <router-view></router-view>
    <v-footer v-show="$route.meta.footerShow"></v-footer>
  </div>
</template>

<script type="ecmascript-6">
import footer from './components/footer';

export default {
  components: {
    'v-footer': footer
  },
  methods: {
  
  },
  created: function() {
    
  }

};
</script>
<style>
.el-input__icon {
  display: none !important;
}
</style>
