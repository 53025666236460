<template lang="html">
	<footer id="hyb_foot" >
    <footer id="hyb_foot" >
		<el-row :gutter="24">
		  <el-col :span="12"  > 
        <router-link :to="{path:'productList'}" exact> 
            <i class="foot_icon bank_icon"></i>
            <b>产品</b>
        </router-link>
      </el-col>
        <el-col :span="12"  > 
        <router-link :to="{path:'my'}" exact> 
            <i class="foot_icon my_icon"></i>
            <b>我的</b>
        </router-link>
      </el-col>
		 
		</el-row>
	</footer> 
	</footer>
</template>

<script>
export default {

     methods: {
        async linkFun(linkName){
          const { data } = await checkLogin();
          const dfmaslkgh = data.res_data || this.$route.query.dfmaslkgh;
    
          if(linkName=="sign"){

          }else if(linkName=="my"){

          }
        }
     }
  };
</script>

<style lang="scss">
footer{z-index: 2;left: 0; height:2.5rem;line-height: 2.5rem;background: #fff; border-top: 1px solid #e3e3e3;position: fixed; bottom: 0;  width: 100%;padding:0.5rem 0;}
footer a{font-size:.5rem;color: #333;} 
footer .el-col{text-align: center;    position: relative;    line-height: 3.5rem;}
footer a.router-link-active b{color: #356BFF;font-weight:bold;}
footer .el-col i.foot_icon{width: 1.3rem; height: 1.5rem; display: block; left: 50%; margin-left: -0.65rem; position: absolute; }
footer .el-col a.router-link-active i.bank_icon{background: url(../assets/img/pro_icon_a.png) no-repeat;   background-size: 100%;}
footer .el-col a.router-link-active i.my_icon{background: url(../assets/img/my_icon_a.png) no-repeat;   background-size: 100%;}
footer .el-col i.bank_icon{background: url(../assets/img/pro_icon.png) no-repeat;background-size: 100%;}
footer .el-col i.my_icon{background: url(../assets/img/my_icon.png) no-repeat; background-size: 100%;}
</style>
