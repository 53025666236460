import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const clearLoginMessage = (to, from, next) => {
  window.localStorage.removeItem('jwtToken');
  next();
};

const routes = [
  {
    path: '/',
    redirect: '/login'
  },{
    path: '/login',//登录
    component: () => import('../views/login.vue')
  },
  {
    path: '/productList',//产品列表
    component: () => import('../views/productList.vue'),meta: { footerShow: true } 
  },{
    path: '/productDetail',//产品预约
    component: () => import('../views/productDetail.vue'),smeta: { footerShow: true } 
  },{
    path: '/order',//产品预约
    component: () => import('../views/order.vue')
  },{
    path: '/result',//产品预约结果
    component: () => import('../views/result.vue')
  },
  {
    path: '/signList',//签约列表
    component: () => import('../views/signList.vue')
  },{
    path: '/signDetail',//签约详情
    component: () => import('../views/signDetail.vue')
  },{
    path: '/sign',//签约
    component: () => import('../views/sign.vue')
  },{
    path: '/signResult',//签约结果
    component: () => import('../views/signResult.vue')
  },{
    path: '/lookSignDetail',//查看签约详情
    component: () => import('../views/lookSignDetail.vue')
  },{
    path: '/paymentPlan',//查看分配计划
    component: () => import('../views/paymentPlan.vue')
  },{
    path: '/riskAssessment',//风险测评
    component: () => import('../views/riskAssessment.vue')
  },{
    path: '/riskScore',//风险测评标准
    component: () => import('../views/riskScore.vue')
  },{
    path: '/upLoadCertificate',//上传证件
    component: () => import('../views/upLoadCertificate.vue')
  },{
    path: '/changeCardResult',//上传证件结果
    component: () => import('../views/changeCardResult.vue')
  },{
    path: '/myBank',//银行卡
    component: () => import('../views/myBank.vue')
  },{
    path: '/myBankInfo',//银行卡详情
    component: () => import('../views/myBankInfo.vue')
  },{
    path: '/realName',//实名认证（身份证）
    component: () => import('../views/realName.vue')
  },{
    path: '/realNameBank',//实名绑卡
    component: () => import('../views/realNameBank.vue')
  },{
    path: '/resultReal',//实名认证结果（身份证）
    component: () => import('../views/resultReal.vue')
  },{
    path: '/resultBank',//绑卡结果
    component: () => import('../views/resultBank.vue')
  },{
    path: '/set',//个人信息
    component: () => import('../views/set.vue')
  },{
    path: '/privacySettings',//隐私协议
    component: () => import('../views/privacySettings.vue')
  },{
    path: '/privacySettingsDetail',//隐私协议
    component: () => import('../views/privacySettingsDetail.vue')
  },{
    path: '/landingpage',//邀请好友
    component: () => import('../views/landingpage.vue')
  },{
    path: '/my',//我的
    component: () => import('../views/my.vue'),meta: { footerShow: true } 
  },{
    path: '/personalCenter',//理财经理-个人中心
    component: () => import('../views/personalCenter.vue')
  },{
    path: '/invitatRecord',//理财经理-邀请记录
    component: () => import('../views/invitatRecord.vue')
  },{
    path: '/toLogin',//理财经理-登录
    component: () => import('../views/toLogin.vue')
  }
  
  
];
const router = new VueRouter({ routes });
export default router;
